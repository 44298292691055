.agreement {
    margin: 0 20px;
    padding: 0 10px;
    max-height: 400px;
    overflow-y: scroll;
}

.instructions {
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-top: 20px
}