.header .header-top {
    background-color: #ffffff;
}

.topbar {
    background-color: #ffffff;
    color: #003764;
}

.header-tabs .nav-item .nav-link {
    color: #003764;
}

.header-tabs .nav-item .nav-link:focus,
.header-tabs .nav-item .nav-link:hover {
    color: #003764;
}

.logo {
    height: 45px;
}

.title {
    font-family: Noto Sans;
    font-size: xx-large;
    font-weight: 400;
    padding-top: 10px;
}

.circle {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #A556A2;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }
